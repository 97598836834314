body {
  background: radial-gradient(circle, #8AB1AA 40%, #5EACC2, #1F81AF);
  font-family: Oxygen, sans-serif; }

.statusSelect {
  width: 370px; }

.scorecard {
  margin-top: 100px; }

.scoreboard {
  width: 310px;
  height: 128px;
  border-radius: 17px;
  background: linear-gradient(-60deg, #46404f 53%, #4B4452 53%);
  box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.25); }

.team {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  font-size: 20pt;
  color: white; }
  .team .name {
    flex-shrink: 0;
    width: 52px;
    display: flex;
    justify-content: center;
    letter-spacing: 2px; }
  .team:nth-child(1) .rank {
    border-top-left-radius: 17px; }
  .team:nth-child(3) .rank {
    border-bottom-left-radius: 17px; }
  .team .rank {
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16pt;
    font-weight: bold;
    flex-shrink: 0; }
  .team .logo {
    width: 50px;
    margin: 25px; }
  .team .score {
    flex-shrink: 0;
    width: 50px;
    display: flex;
    justify-content: center;
    margin: 30px;
    margin-left: 25px;
    letter-spacing: 2px; }
  .team .record {
    font-size: 16pt;
    color: #746f7f;
    padding: 1px;
    margin-right: 30px; }

.win {
  color: #EABF4F; }

.divider {
  height: 1px;
  width: 310px;
  position: absolute;
  margin-left: 17px;
  font-size: 10pt;
  color: white; }
  .divider::before {
    content: "";
    display: inline-block;
    position: absolute;
    height: 1px;
    width: 215px;
    background-color: #746f7f; }
  .divider p {
    position: absolute;
    font-weight: bold;
    left: 230px;
    top: -17px;
    text-align: center;
    align-items: center;
    justify-content: center; }

.links {
  margin-top: 15px;
  align-content: left;
  justify-content: left;
  text-align: left;
  color: #31283C; }

.stream {
  margin-left: 10px; }

a {
  text-decoration: none;
  color: #31283C; }

a:hover {
  color: black; }
